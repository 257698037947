import { useCallback, useEffect, useMemo, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import { useAuth0Token } from './useAuth0Token';
import useSelector from '../redux/typedHooks';
import { InvoicePaymentData } from '../../../types/InvoiceType';

export enum InvoiceStatus {
  PAID = 'Paid',
  CANCELED = 'Canceled',
  NONE = 'None',
}

export const useInvoice = (paymentId: string): InvoicePaymentData | undefined => {
  const { simpleToken } = useAuth0Token();
  const [order, setInvoicePayment] = useState<InvoicePaymentData>();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { apimBaseUrl, apimMembershipApi, signalR, apimContentHub } = useSelector((state) => state.application);

  // TODO: get the address to the signalR endpoint from redux application state <- server env variable

  useEffect(() => {
    if (apimBaseUrl) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${signalR}/${apimMembershipApi}`, {
          headers: {
            'Ocp-Apim-Subscription-Key': apimContentHub,
          },
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [paymentId, apimBaseUrl, apimContentHub, apimMembershipApi, signalR]);

  // Function subscribing to signalR
  const startConnection = useMemo(() => {
    if (connection && apimContentHub && apimMembershipApi && connection && paymentId && signalR && simpleToken) {
      return async () => {
        if (connection && apimContentHub && apimMembershipApi && connection && paymentId && signalR && simpleToken) {
          try {
            await connection.start();
            connection.on(paymentId, (rawMessage) => {
              const message = JSON.parse(rawMessage);
              if (message.type === 1 && message.target === paymentId) {
                const messagedInvoice = message.arguments[0];
                setInvoicePayment(messagedInvoice);
              }
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Connection failed');
          }
          try {
            const res = await axios.get<InvoicePaymentData>(
              `${signalR}/${apimMembershipApi}/invoices/pay/${paymentId}`,
              {
                headers: { Authorization: `Bearer ${simpleToken}`, 'Ocp-Apim-Subscription-Key': apimContentHub },
              },
            );
            setInvoicePayment(res.data);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      };
    }
    return null;
  }, [apimContentHub, apimMembershipApi, connection, paymentId, signalR, simpleToken]);

  // Function cleaning up signalR subscription
  const endConnection = useCallback(async () => {
    if (connection) {
      try {
        await connection.stop();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Connection could not be stopped!');
      }
    }
  }, [connection]);

  useEffect(() => {
    if (startConnection) {
      startConnection().then();
      return () => {
        endConnection().then();
      };
    }
    return () => {};
  }, [startConnection, endConnection]);

  return order;
};
